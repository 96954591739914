<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleSearchCreate
          :page-title="!$language ? 'Exams' : 'পরীক্ষা'"
          placeholder="Search By Exam Name"
          :create-btn="!$language ? 'Create Exam' : 'পরীক্ষা তৈরি করুন'"
          @createEvent="$router.push({ name: 'exam-create' })"
        ></PageTitleSearchCreate>
      </v-col>

      <template v-if="!renderHtml">
        <div id="loading-wrapper">
          <div id="loading-text">LOADING</div>
          <div id="loading-content"></div>
        </div>
      </template>

      <template v-else-if="networkError">
        <no-content-component
          :message="networkErrorNoContentMessage"
        ></no-content-component>
      </template>

      <template v-else-if="noContentCondition">
        <no-content-component
          :message="noContentMessage"
        ></no-content-component>
      </template>

      <template v-else>
        <v-col
          v-for="(exam, i) in exams"
          :key="i"
          xl="4"
          lg="6"
          md="12"
          sm="12"
          class="m-4"
        >
          <m-exam-list-item
            :exam="exam"
            :shares-count="shareCount(exam)"
            @share="shareExam(i)"
            @delete="confirmExamDelete(exam)"
          ></m-exam-list-item>
        </v-col>
      </template>

      <v-container v-if="shareExamPanel">
        <exam-share-panel
          :exam-to-share="examToShare"
          :course-id="courseId"
          :share-panel="shareExamPanel"
          :should_show_loading_icon="should_show_loading_icon"
          @closePanel="closeExamPanel"
          @shareExam="submitFormData"
        ></exam-share-panel>
      </v-container>
    </v-row>
    <dialog-delete
      v-model="show_delete_dialog"
      item-text="Exam"
      :deleting="deleting"
      :item="exam_for_delete"
      @confirm-remove="removeExam"
    ></dialog-delete>
  </v-container>
</template>

<script>
import { ExamProvider } from "#ecf/exam/mixins";
import batchService from "#ecf/batch/services/BatchService";
import { ExamApi } from "#ecf/exam/services/ExamService";
import PageTitleSearchCreate from "@ecf/global/components/PageTitleSearchCreate";
import MExamListItem from "#ecf/exam/components/MExamListItem";
import ExamSharePanel from "#ecf/exam/components/ExamSharePanel";
import NoContentComponent from "/global/components/NoContentComponent";
import DialogDelete from "@ef/global/components/DialogDelete.vue";

export default {
  name: "ExamListPage",
  components: {
    DialogDelete,
    MExamListItem,
    ExamSharePanel,
    PageTitleSearchCreate,
    NoContentComponent
  },
  mixins: [ExamProvider],
  props: {
    courseId: String
  },
  inject: ["$courseOwnerId", "$courseId"],
  data() {
    return {
      renderHtml: false,
      networkError: false,
      shareExamPanel: false,
      examToShare: undefined,
      share_idx: undefined,
      sharing: {},
      deleting: false,
      should_show_loading_icon: false,
      batches: {},
      show_delete_dialog: undefined,
      exam_for_delete: undefined
    };
  },
  computed: {
    noContentCondition() {
      return this.exams?.length === 0;
    },
    noContentMessage() {
      return !this.$language
        ? "You currently have no exams"
        : "বর্তমানে আপনার কোনো পরীক্ষা নেই";
    },
    networkErrorNoContentMessage() {
      return !this.$language
        ? "Your exams are currently not available"
        : "বর্তমানে আপনার পরীক্ষা সংক্রান্ত তথ্য এনে দেয়া সম্ভব হচ্ছে না";
    }
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system"
      ]);
      this.networkError = true;
      this.renderHtml = true;
      return;
    }
    this.renderHtml = false;
    await this.$store.dispatch("exam/list", {
      courseId: this.courseId,
      owner_id: this.$courseOwnerId()
    });
    await this.fetchBatches();
    this.renderHtml = true;
  },
  methods: {
    shareCount(exam) {
      let examSharsObject = this.$store.state.exam.examShares[exam.id];
      let count = 0;
      for (let batch of this.batches) {
        if (batch.batch_id in examSharsObject) count++;
      }
      return count;
    },
    async submitFormData(formData) {
      if (this.should_show_loading_icon) return;
      try {
        let finalFormData = [];
        this.should_show_loading_icon = true;
        if (Object.keys(formData).length > 0) {
          for (var formKey in formData) {
            this.$set(formData[formKey], "course_id", this.courseId);
            this.$set(formData[formKey], "exam_id", this.examToShare.id);
            this.$set(
              formData[formKey],
              "time_zone",
              Intl.DateTimeFormat().resolvedOptions().timeZone
            );
            this.$set(
              formData[formKey],
              "partner_code",
              this.$route.params.partner_code
            );
            finalFormData.push(formData[formKey]);
          }
          this.renderHtml = false;
          await ExamApi.shareMultipleExam(finalFormData);
          await this.$store.dispatch("exam/list", {
            courseId: this.courseId,
            owner_id: this.$courseOwnerId()
          });
          await this.fetchBatches();
        } else {
          this.$set(formData, "course_id", this.courseId);
          this.$set(formData, "exam_id", this.examToShare.id);
          this.$set(formData, "partner_code", this.$route.params.partner_code);
          this.renderHtml = false;
          await ExamApi.unshareMultipleExam(formData);
          await this.$store.dispatch("exam/list", {
            courseId: this.courseId,
            owner_id: this.$courseOwnerId()
          });
          await this.fetchBatches();
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.renderHtml = true;
        this.should_show_loading_icon = false;
      }
    },
    closeExamPanel() {
      this.shareExamPanel = false;
    },
    async share(share_data) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system"
        ]);
        return;
      }
      if (this.sharing[this.examToShare.id]) return;
      try {
        this.$set(this.sharing, this.examToShare.id, true);
        await this.$store.dispatch("exam/shareExam", share_data);
        await this.fetchCourseworks();
        // this.examToShare = this.exams[this.share_idx];
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message)
        ]);
      } finally {
        this.$set(this.sharing, this.examToShare.object_id, false);
      }
    },
    async shareExam(idx) {
      this.share_idx = idx;
      this.examToShare = this.exams[idx];
      this.shareExamPanel = true;
    },
    async fetchBatches() {
      try {
        this.batches = await batchService.find(this.courseId);
        this.shareExamPanel = false;
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message)
        ]);
      }
    },
    confirmExamDelete(Exam) {
      this.show_delete_dialog = true;
      this.exam_for_delete = Exam;
    },
    async removeExam(exam) {
      if (this.deleting) return;
      try {
        this.deleting = true;
        await this.$store.dispatch("exam/delete", {
          examId: exam.id,
          courseId: this.courseId
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
      }
    }
  }
};
</script>
