var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-app",
        [
          _c(
            "v-dialog",
            {
              staticClass: "custom-font-family",
              attrs: {
                width: _vm.$vuetify.breakpoint.mdAndDown
                  ? _vm.setSmallScreenStyle()
                  : _vm.$vuetify.breakpoint.lgOnly
                  ? _vm.setMediumScreenStyle()
                  : _vm.setLargeScreenStyle(),
                persistent: "",
              },
              model: {
                value: _vm.sharePanel,
                callback: function ($$v) {
                  _vm.sharePanel = $$v
                },
                expression: "sharePanel",
              },
            },
            [
              _vm.make_form_visible
                ? _c(
                    "v-card",
                    { staticClass: "mk-share-panel-header-card" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "pa-0 ma-0 mk-share-panel-header-section",
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "ma-0 pr-0 mk-share-panel-header-title-section",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pa-0 ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 ma-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "pa-0 ma-0 mk-share-panel-title text-truncate",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.examToShare.title) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "pa-0 ma-0 mk-batch-header-title-section",
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 ma-0",
                              attrs: { cols: "12", lg: "4", xl: "4" },
                            },
                            [
                              _c("h1", { staticClass: "pr-0 py-0 ma-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !_vm.$language
                                        ? "List of Batches"
                                        : "ব্যাচের লিস্ট"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c("v-col", {
                            staticClass: "pa-0 ma-0",
                            attrs: { cols: "12", lg: "8", xl: "8" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "pa-0 ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 ma-0",
                              attrs: { cols: "5", lg: "4", xl: "4" },
                            },
                            [
                              _c(
                                "v-item-group",
                                { attrs: { multiple: "" } },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mk-share-batch-section",
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "pa-0 mr-0 my-0 mk-batch-list-item",
                                            },
                                            _vm._l(
                                              _vm.batches,
                                              function (batch) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: batch.batch_id,
                                                    staticClass:
                                                      "px-0 pb-0 mr-0 my-0",
                                                    attrs: {
                                                      id:
                                                        "title" +
                                                        batch.batch_id,
                                                      cols: "12",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showForm(
                                                          batch.batch_id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-item",
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center white--text mk-share-batch-card",
                                                            staticStyle: {
                                                              border:
                                                                "thin solid rgba(0, 0, 0, 0.12) !important",
                                                            },
                                                            attrs: {
                                                              outlined: "",
                                                              flat: "",
                                                              color: _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ].is_clicked
                                                                ? "#BBDEFB"
                                                                : _vm
                                                                    .sharePanelFormData[
                                                                    "form" +
                                                                      batch.batch_id
                                                                  ]
                                                                    .should_highlight
                                                                ? "primary"
                                                                : "white",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-scroll-y-transition",
                                                              [
                                                                _vm
                                                                  .sharePanelFormData[
                                                                  "form" +
                                                                    batch.batch_id
                                                                ].is_clicked
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1 text-left black--text text-truncate mk-share-panel-batch-title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              batch.batch_title
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm
                                                                      .sharePanelFormData[
                                                                      "form" +
                                                                        batch.batch_id
                                                                    ]
                                                                      .should_highlight
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1 text-left text-truncate mk-share-panel-batch-title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              batch.batch_title
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1 text-left black--text text-truncate mk-share-panel-batch-title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              batch.batch_title
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "pa-0 ma-0 mk-share-panel-form-section",
                              attrs: { cols: "7", lg: "8", xl: "8" },
                            },
                            _vm._l(_vm.batches, function (batch, idx) {
                              return _c(
                                "v-row",
                                {
                                  key: idx,
                                  staticClass: "pa-0 ma-0 mk-share-panel-form",
                                  attrs: { id: batch.batch_id },
                                },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      staticClass: "pa-0 ma-0",
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.submit.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.valid,
                                        callback: function ($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "pa-0 ma-0 mk-share-panel-form-input-date",
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 ma-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mk-share-panel-alert",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          !_vm.$language
                                                            ? "Share Date Must Be Before Deadline."
                                                            : "শেয়ারের তারিখ অবশ্যই ডেডলাইনের আগে হতে হবে।"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mk-share-panel-time-alert-deadline",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          !_vm.$language
                                                            ? "Deadline must be valid"
                                                            : "ডেডলাইন অবশ্যই বৈধ হতে হবে।"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pl-0 py-0 ma-0 mk-publish-date-section",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                xl: "6",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        transition:
                                                          "scale-transition",
                                                        "offset-y": "",
                                                        "max-width": "290px",
                                                        "min-width": "290px",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mk-date-input-text-field",
                                                                      attrs: {
                                                                        outlined:
                                                                          "",
                                                                        label:
                                                                          !_vm.$language
                                                                            ? "Available Date From"
                                                                            : "তারিখ থেকে",
                                                                        hint: "DD/MM/YYYY format",
                                                                        clearable:
                                                                          "",
                                                                        placeholder:
                                                                          !_vm.$language
                                                                            ? "Select Date"
                                                                            : "তারিখ নির্বাচন করুন",
                                                                        "append-icon":
                                                                          "mdi-calendar-month-outline",
                                                                        dense:
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .mdAndDown ||
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .lgOnly,
                                                                        "rounded-0":
                                                                          "",
                                                                        rules:
                                                                          _vm.rules,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .sharePanelFormData[
                                                                            "form" +
                                                                              batch.batch_id
                                                                          ]
                                                                            .share_date_for_view,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .sharePanelFormData[
                                                                                "form" +
                                                                                  batch.batch_id
                                                                              ],
                                                                              "share_date_for_view",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                              sharePanelFormData['form' + batch.batch_id]\n                                .share_date_for_view\n                            ",
                                                                      },
                                                                    },
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.dialogMap[
                                                            "share" +
                                                              batch.batch_id
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dialogMap,
                                                            "share" +
                                                              batch.batch_id,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dialogMap['share' + batch.batch_id]",
                                                      },
                                                    },
                                                    [
                                                      _c("v-date-picker", {
                                                        attrs: {
                                                          "no-title": "",
                                                          scrollable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.hideInputField(
                                                              batch.batch_id,
                                                              "share"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].share_date,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ],
                                                              "share_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                            sharePanelFormData['form' + batch.batch_id]\n                              .share_date\n                          ",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pr-0 py-0 ma-0 mk-due-date-section",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                xl: "6",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        transition:
                                                          "scale-transition",
                                                        "offset-y": "",
                                                        disabled: _vm
                                                          .sharePanelFormData[
                                                          "form" +
                                                            batch.batch_id
                                                        ].is_static
                                                          ? true
                                                          : false,
                                                        "max-width": "290px",
                                                        "min-width": "290px",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mk-date-input-text-field",
                                                                      attrs: {
                                                                        outlined:
                                                                          "",
                                                                        label:
                                                                          !_vm.$language
                                                                            ? "Available Date To"
                                                                            : "তারিখ পর্যন্ত",
                                                                        placeholder:
                                                                          !_vm.$language
                                                                            ? "Select Date"
                                                                            : "তারিখ নির্বাচন করুন",
                                                                        "append-icon":
                                                                          "mdi-calendar-month-outline",
                                                                        hint: "DD/MM/YYYY format",
                                                                        rules:
                                                                          _vm.rules,
                                                                        dense:
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .mdAndDown ||
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .lgOnly,
                                                                        clearable:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .sharePanelFormData[
                                                                            "form" +
                                                                              batch.batch_id
                                                                          ]
                                                                            .due_date_for_view,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .sharePanelFormData[
                                                                                "form" +
                                                                                  batch.batch_id
                                                                              ],
                                                                              "due_date_for_view",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                              sharePanelFormData['form' + batch.batch_id]\n                                .due_date_for_view\n                            ",
                                                                      },
                                                                    },
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.dialogMap[
                                                            "due" +
                                                              batch.batch_id
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dialogMap,
                                                            "due" +
                                                              batch.batch_id,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dialogMap['due' + batch.batch_id]",
                                                      },
                                                    },
                                                    [
                                                      _c("v-date-picker", {
                                                        attrs: {
                                                          "no-title": "",
                                                          scrollable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.hideInputField(
                                                              batch.batch_id,
                                                              "due"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].due_date,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ],
                                                              "due_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                            sharePanelFormData['form' + batch.batch_id]\n                              .due_date\n                          ",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 ma-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mk-share-panel-time-alert",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          !_vm.$language
                                                            ? "Start Time Must Be Before Due Time."
                                                            : "শুরুর সময় নির্ধারিত সময়ের আগে হতে হবে।"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex pr-0 py-0 ma-0 mk-due-date-section",
                                              style: _vm.isSame(
                                                _vm.sharePanelFormData[
                                                  "form" + batch.batch_id
                                                ].share_date_for_view,
                                                _vm.sharePanelFormData[
                                                  "form" + batch.batch_id
                                                ].due_date_for_view
                                              )
                                                ? "display:flex;"
                                                : "display:none;",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                xl: "6",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        "offset-y": "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mk-time-input-text-field",
                                                                      attrs: {
                                                                        id:
                                                                          "time-input-from-" +
                                                                          idx,
                                                                        outlined:
                                                                          "",
                                                                        label:
                                                                          !_vm.$language
                                                                            ? "Available Time From"
                                                                            : "সময় থেকে",
                                                                        placeholder:
                                                                          !_vm.$language
                                                                            ? "Select Time"
                                                                            : "সময় নির্বাচন করুন",
                                                                        "append-icon":
                                                                          "mdi-calendar-month-outline",
                                                                        dense:
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .mdAndDown ||
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .lgOnly,
                                                                        hint: "HH:MM format",
                                                                        rules:
                                                                          !_vm.isEmpty(
                                                                            _vm
                                                                              .sharePanelFormData[
                                                                              "form" +
                                                                                batch.batch_id
                                                                            ]
                                                                              .available_time_from_for_view,
                                                                            _vm
                                                                              .sharePanelFormData[
                                                                              "form" +
                                                                                batch.batch_id
                                                                            ]
                                                                              .available_time_to_for_view
                                                                          )
                                                                            ? _vm.rules
                                                                            : [],
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .sharePanelFormData[
                                                                            "form" +
                                                                              batch.batch_id
                                                                          ]
                                                                            .available_time_from_for_view,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .sharePanelFormData[
                                                                                "form" +
                                                                                  batch.batch_id
                                                                              ],
                                                                              "available_time_from_for_view",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                              sharePanelFormData['form' + batch.batch_id]\n                                .available_time_from_for_view\n                            ",
                                                                      },
                                                                    },
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0",
                                                        },
                                                        [
                                                          _c("time-picker", {
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.setTimeData(
                                                                  $event,
                                                                  "from",
                                                                  idx,
                                                                  batch.batch_id
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex pr-0 py-0 ma-0 mk-due-date-section",
                                              style: _vm.isSame(
                                                _vm.sharePanelFormData[
                                                  "form" + batch.batch_id
                                                ].share_date_for_view,
                                                _vm.sharePanelFormData[
                                                  "form" + batch.batch_id
                                                ].due_date_for_view
                                              )
                                                ? "display:flex;"
                                                : "display:none;",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                xl: "6",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        "offset-y": "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mk-time-input-text-field",
                                                                      attrs: {
                                                                        id:
                                                                          "time-input-to-" +
                                                                          idx,
                                                                        outlined:
                                                                          "",
                                                                        label:
                                                                          !_vm.$language
                                                                            ? "Available Time To"
                                                                            : "সময় পর্যন্ত",
                                                                        placeholder:
                                                                          !_vm.$language
                                                                            ? "Select Date"
                                                                            : "সময় নির্বাচন করুন",
                                                                        "append-icon":
                                                                          "mdi-calendar-month-outline",
                                                                        hint: "HH:MM format",
                                                                        dense:
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .mdAndDown ||
                                                                          _vm
                                                                            .$vuetify
                                                                            .breakpoint
                                                                            .lgOnly,
                                                                        rules:
                                                                          !_vm.isEmpty(
                                                                            _vm
                                                                              .sharePanelFormData[
                                                                              "form" +
                                                                                batch.batch_id
                                                                            ]
                                                                              .available_time_from_for_view,
                                                                            _vm
                                                                              .sharePanelFormData[
                                                                              "form" +
                                                                                batch.batch_id
                                                                            ]
                                                                              .available_time_to_for_view
                                                                          )
                                                                            ? _vm.rules
                                                                            : [],
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .sharePanelFormData[
                                                                            "form" +
                                                                              batch.batch_id
                                                                          ]
                                                                            .available_time_to_for_view,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .sharePanelFormData[
                                                                                "form" +
                                                                                  batch.batch_id
                                                                              ],
                                                                              "available_time_to_for_view",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                              sharePanelFormData['form' + batch.batch_id]\n                                .available_time_to_for_view\n                            ",
                                                                      },
                                                                    },
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0",
                                                        },
                                                        [
                                                          _c("time-picker", {
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.setTimeData(
                                                                  $event,
                                                                  "to",
                                                                  idx,
                                                                  batch.batch_id
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "px-0 pb-0 ma-0 mk-share-panel-form-input-mark-share-btn",
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pr-0 py-0 ma-0 mk-share-btn-section",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                xl: "6",
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "mk-share-switch",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_share,
                                                          expression:
                                                            "\n                            sharePanelFormData['form' + batch.batch_id]\n                              .is_share\n                          ",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                        disabled:
                                                          !_vm.isAllFieldVerified(
                                                            batch.batch_id
                                                          ),
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          _vm
                                                            .sharePanelFormData[
                                                            "form" +
                                                              batch.batch_id
                                                          ].is_share
                                                        )
                                                          ? _vm._i(
                                                              _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ].is_share,
                                                              null
                                                            ) > -1
                                                          : _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_share,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ].is_share,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  _vm
                                                                    .sharePanelFormData[
                                                                    "form" +
                                                                      batch.batch_id
                                                                  ],
                                                                  "is_share",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  _vm
                                                                    .sharePanelFormData[
                                                                    "form" +
                                                                      batch.batch_id
                                                                  ],
                                                                  "is_share",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ],
                                                              "is_share",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _c("div", {
                                                      staticClass:
                                                        "mk-share-slider",
                                                    }),
                                                    _c("div", {
                                                      staticClass: "text",
                                                    }),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("v-col", {
                                            staticClass:
                                              "pl-0 py-0 ma-0 mk-total-mark-section",
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              xl: "6",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "pb-0 ma-0 mk-share-panel-form-input-mark-share-btn",
                                          staticStyle: {
                                            "padding-left": "65px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "px-0 py-0 ma-0 mk-share-btn-section",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                xl: "6",
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "mk-share-switch",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_public,
                                                          expression:
                                                            "\n                            sharePanelFormData['form' + batch.batch_id]\n                              .is_public\n                          ",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          _vm
                                                            .sharePanelFormData[
                                                            "form" +
                                                              batch.batch_id
                                                          ].is_public
                                                        )
                                                          ? _vm._i(
                                                              _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ].is_public,
                                                              null
                                                            ) > -1
                                                          : _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_public,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ].is_public,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  _vm
                                                                    .sharePanelFormData[
                                                                    "form" +
                                                                      batch.batch_id
                                                                  ],
                                                                  "is_public",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  _vm
                                                                    .sharePanelFormData[
                                                                    "form" +
                                                                      batch.batch_id
                                                                  ],
                                                                  "is_public",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ],
                                                              "is_public",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _c("div", {
                                                      staticClass:
                                                        "mk-share-slider",
                                                    }),
                                                    _c("div", {
                                                      staticClass:
                                                        "public-text",
                                                    }),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pl-0 py-0 ma-0 mk-total-mark-section d-flex justify-start",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                xl: "6",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center",
                                                  staticStyle: {
                                                    height: "100%",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Is it available publicly? "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "pb-0 ma-0 mk-share-panel-form-input-mark-share-btn",
                                          staticStyle: {
                                            "padding-left": "65px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "px-0 py-0 ma-0 mk-share-btn-section",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                xl: "6",
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "mk-share-switch",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_static,
                                                          expression:
                                                            "\n                            sharePanelFormData['form' + batch.batch_id]\n                              .is_static\n                          ",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          _vm
                                                            .sharePanelFormData[
                                                            "form" +
                                                              batch.batch_id
                                                          ].is_static
                                                        )
                                                          ? _vm._i(
                                                              _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ].is_static,
                                                              null
                                                            ) > -1
                                                          : _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_static,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ].is_static,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  _vm
                                                                    .sharePanelFormData[
                                                                    "form" +
                                                                      batch.batch_id
                                                                  ],
                                                                  "is_static",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  _vm
                                                                    .sharePanelFormData[
                                                                    "form" +
                                                                      batch.batch_id
                                                                  ],
                                                                  "is_static",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ],
                                                              "is_static",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _c("div", {
                                                      staticClass:
                                                        "mk-share-slider",
                                                    }),
                                                    _c("div", {
                                                      staticClass:
                                                        "static-text",
                                                    }),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pl-0 py-0 ma-0 mk-total-mark-section d-flex justify-start",
                                              attrs: {
                                                cols: "12",
                                                lg: "6",
                                                xl: "6",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center",
                                                  staticStyle: {
                                                    height: "100%",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Is it shared for a limited time? "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mk-share-cancle-done-btn-section" },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "px-10 pb-8",
                              attrs: { "no-gutters": "", justify: "end" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pl-0 py-0 ma-0 mk-share-panel-cancel",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "custom-font-family",
                                      attrs: {
                                        depressed: "",
                                        tile: "",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("closePanel")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language
                                              ? "Cancel"
                                              : "বাতিল করুন"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pa-0 mx-0 mx-0 mk-share-panel-done",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "custom-font-family",
                                      attrs: {
                                        depressed: "",
                                        tile: "",
                                        plain: "",
                                        loading: _vm.should_show_loading_icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.organizeDataAndSend()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language
                                              ? "Done"
                                              : "সম্পন্ন করুন"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-card",
                        { staticClass: "loading-mk-share-panel-header-card" },
                        [
                          _c("div", { attrs: { id: "loading-wrapper" } }, [
                            _c("div", { attrs: { id: "loading-text" } }, [
                              _vm._v("LOADING"),
                            ]),
                            _c("div", { attrs: { id: "loading-content" } }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }