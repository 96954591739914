import { Logger } from "/global/utils/helpers";

export const ExamProvider = {
  props: {
    examId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fetchingAllExams: false,
      fetchingExam: {}
    };
  },
  computed: {
    exams() {
      return this.$store.getters["exam/exams"];
    },
    exam() {
      return this.examId
        ? this.$store.state.exam.exams[this.examId]
        : undefined;
    },
    examQuestions() {
      return this.exam ? this.$store.state.exam.examQuestions[this.examId] : [];
    }
  },
  methods: {
    async listExams(courseId) {
      try {
        await this.$store.dispatch("exam/list", { courseId });
      } catch (e) {
        Logger.log(e);
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e)
        ]);
      }
    },
    async getExam(id, course_id, owner_id) {
      try {
        return await this.$store.dispatch("exam/get", {
          id,
          course_id,
          owner_id
        });
      } catch (e) {
        Logger.log(e);
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e)
        ]);
      }
    }
  }
};

export const ExamPackageProvider = {
  props: {
    examPackageId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fetchingAllExamPackages: false,
      fetchingExamPackage: {}
    };
  },
  computed: {
    examPackages() {
      return this.$store.getters["examPackage/packages"];
    },
    examPackage() {
      return this.examPackageId
        ? this.$store.state.examPackage.examPackages[this.examPackageId]
        : undefined;
    }
  },
  methods: {
    async listExamPackages() {
      try {
        await this.$store.dispatch("examPackage/list");
      } catch (e) {
        Logger.log(e);
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e)
        ]);
      }
    },
    async getExamPackage(id) {
      try {
        return await this.$store.dispatch("examPackage/get", { id });
      } catch (e) {
        Logger.log(e);
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e)
        ]);
      }
    }
  }
};

export const ExamPackagePurchaseProvider = {
  props: {
    examPackageId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fetchingAllPurchases: false
    };
  },
  computed: {
    purchases() {
      return this.$store.state.examPackage.purchases[this.examPackageId];
    }
  },
  methods: {
    async listPurchases() {
      try {
        await this.$store.dispatch("examPackage/getPurchases", {
          packageId: this.examPackageId
        });
      } catch (e) {
        Logger.log(e);
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e)
        ]);
      }
    }
  }
};
