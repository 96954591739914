import { Backend } from "~/utils/backend";

const backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
const $backend = new Backend(process.env.VUE_APP_BACKEND_BOLT_TEACHERS_URL);
import { checkStatus } from "/global/utils/helpers";

const ExamMapper = {
  fromRemote(exam) {
    return {
      title: exam.title,
      id: exam.id,
      instruction: exam.instruction,
      points: exam.total_points,
      duration: exam.total_duration
    };
  }
};

export const ExamApi = {
  listExams() {
    return [];
    // return $backend
    //   .get("teachers_list_exams", { params: {} })
    //   .then(({ data }) => data.content.exams.map(ExamMapper.fromRemote));
  },
  listAddedExams(courseId) {
    return $backend
      .get("teachers_list_exams", { params: { course_id: courseId } })
      .then(checkStatus)
      .then(({ data }) => data.content.exams.map(e => ({ id: e.exam_id })));
  },
  addExam(examId, courseId) {
    return backend
      .post("teachers_add_exam", {
        exam_id: examId,
        course_id: courseId
      })
      .then(checkStatus);
  },
  removeExam(examId, courseId) {
    return backend
      .post("teachers_delete_exam", {
        exam_id: examId,
        course_id: courseId
      })
      .then(checkStatus);
  },
  shareExam(examId, batchId, courseId, startDate, endDate) {
    return backend
      .post("teachers_share_exam", {
        exam_id: examId,
        batch_id: batchId,
        course_id: courseId,
        start_date: startDate,
        end_date: endDate
      })
      .then(checkStatus);
  },

  shareMultipleExam(data) {
    return backend
      .post(
        "teachers_share_multiple_exam",
        { params: data },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus);
  },

  unshareMultipleExam(data) {
    return backend
      .post("teachers_unshare_multiple_exam", data)
      .then(checkStatus);
  },

  listSharedExams(courseId, batchId) {},
  listSharedBatches(examId, courseId) {
    return backend
      .get("teachers_list_shared_exams", {
        params: {
          exam_id: examId,
          course_id: courseId
        }
      })
      .then(checkStatus)
      .then(({ data }) => data.content.shared_exams);
  },
  unshareExam(examId, batchId, courseId) {
    return backend
      .post("teachers_unshare_exam", {
        exam_id: examId,
        batch_id: batchId,
        course_id: courseId
      })
      .then(checkStatus);
  }
};
