<template>
  <v-row justify="center">
    <v-app>
      <v-dialog
        v-model="sharePanel"
        :width="
          $vuetify.breakpoint.mdAndDown
            ? setSmallScreenStyle()
            : $vuetify.breakpoint.lgOnly
            ? setMediumScreenStyle()
            : setLargeScreenStyle()
        "
        class="custom-font-family"
        persistent
      >
        <v-card v-if="make_form_visible" class="mk-share-panel-header-card">
          <v-row class="pa-0 ma-0 mk-share-panel-header-section">
            <v-col
              cols="12"
              class="ma-0 pr-0 mk-share-panel-header-title-section"
            >
              <v-row class="pa-0 ma-0">
                <v-col cols="12" class="pa-0 ma-0">
                  <h4 class="pa-0 ma-0 mk-share-panel-title text-truncate">
                    {{ examToShare.title }}
                  </h4>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0 mk-batch-header-title-section">
            <v-col cols="12" lg="4" xl="4" class="pa-0 ma-0">
              <h1 class="pr-0 py-0 ma-0">
                {{ !$language ? "List of Batches" : "ব্যাচের লিস্ট" }}
              </h1>
            </v-col>
            <v-col cols="12" lg="8" xl="8" class="pa-0 ma-0"></v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col cols="5" lg="4" xl="4" class="pa-0 ma-0">
              <v-item-group multiple>
                <v-container class="pa-0 ma-0">
                  <div class="mk-share-batch-section">
                    <v-row class="pa-0 mr-0 my-0 mk-batch-list-item">
                      <v-col
                        v-for="batch in batches"
                        :id="'title' + batch.batch_id"
                        :key="batch.batch_id"
                        class="px-0 pb-0 mr-0 my-0"
                        cols="12"
                        @click="showForm(batch.batch_id)"
                      >
                        <v-item>
                          <v-card
                            style="
                              border: thin solid rgba(0, 0, 0, 0.12) !important;
                            "
                            outlined
                            flat
                            :color="
                              sharePanelFormData['form' + batch.batch_id]
                                .is_clicked
                                ? '#BBDEFB'
                                : sharePanelFormData['form' + batch.batch_id]
                                    .should_highlight
                                ? 'primary'
                                : 'white'
                            "
                            class="d-flex align-center white--text mk-share-batch-card"
                          >
                            <v-scroll-y-transition>
                              <div
                                v-if="
                                  sharePanelFormData['form' + batch.batch_id]
                                    .is_clicked
                                "
                                class="flex-grow-1 text-left black--text text-truncate mk-share-panel-batch-title"
                              >
                                {{ batch.batch_title }}
                              </div>
                              <div
                                v-else-if="
                                  sharePanelFormData['form' + batch.batch_id]
                                    .should_highlight
                                "
                                class="flex-grow-1 text-left text-truncate mk-share-panel-batch-title"
                              >
                                {{ batch.batch_title }}
                              </div>
                              <div
                                v-else
                                class="flex-grow-1 text-left black--text text-truncate mk-share-panel-batch-title"
                              >
                                {{ batch.batch_title }}
                              </div>
                            </v-scroll-y-transition>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
              </v-item-group>
            </v-col>
            <v-col
              cols="7"
              lg="8"
              xl="8"
              class="pa-0 ma-0 mk-share-panel-form-section"
            >
              <v-row
                v-for="(batch, idx) in batches"
                :id="batch.batch_id"
                :key="idx"
                class="pa-0 ma-0 mk-share-panel-form"
              >
                <v-form
                  v-model="valid"
                  class="pa-0 ma-0"
                  @submit.prevent="submit"
                >
                  <v-row class="pa-0 ma-0 mk-share-panel-form-input-date">
                    <v-col cols="12" class="pa-0 ma-0">
                      <div class="mk-share-panel-alert">
                        <p>
                          {{
                            !$language
                              ? "Share Date Must Be Before Deadline."
                              : "শেয়ারের তারিখ অবশ্যই ডেডলাইনের আগে হতে হবে।"
                          }}
                        </p>
                      </div>
                      <div class="mk-share-panel-time-alert-deadline">
                        <p>
                          {{
                            !$language
                              ? "Deadline must be valid"
                              : "ডেডলাইন অবশ্যই বৈধ হতে হবে।"
                          }}
                        </p>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pl-0 py-0 ma-0 mk-publish-date-section"
                    >
                      <div>
                        <v-menu
                          v-model="dialogMap['share' + batch.batch_id]"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="
                                sharePanelFormData['form' + batch.batch_id]
                                  .share_date_for_view
                              "
                              outlined
                              :label="
                                !$language
                                  ? 'Available Date From'
                                  : 'তারিখ থেকে'
                              "
                              hint="DD/MM/YYYY format"
                              clearable
                              class="mk-date-input-text-field"
                              :placeholder="
                                !$language
                                  ? 'Select Date'
                                  : 'তারিখ নির্বাচন করুন'
                              "
                              append-icon="mdi-calendar-month-outline"
                              :dense="
                                $vuetify.breakpoint.mdAndDown ||
                                  $vuetify.breakpoint.lgOnly
                              "
                              rounded-0
                              :rules="rules"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="
                              sharePanelFormData['form' + batch.batch_id]
                                .share_date
                            "
                            no-title
                            scrollable
                            @input="hideInputField(batch.batch_id, 'share')"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pr-0 py-0 ma-0 mk-due-date-section"
                    >
                      <div>
                        <v-menu
                          v-model="dialogMap['due' + batch.batch_id]"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          :disabled="
                            sharePanelFormData['form' + batch.batch_id]
                              .is_static
                              ? true
                              : false
                          "
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="
                                sharePanelFormData['form' + batch.batch_id]
                                  .due_date_for_view
                              "
                              outlined
                              :label="
                                !$language
                                  ? 'Available Date To'
                                  : 'তারিখ পর্যন্ত'
                              "
                              :placeholder="
                                !$language
                                  ? 'Select Date'
                                  : 'তারিখ নির্বাচন করুন'
                              "
                              append-icon="mdi-calendar-month-outline"
                              hint="DD/MM/YYYY format"
                              :rules="rules"
                              :dense="
                                $vuetify.breakpoint.mdAndDown ||
                                  $vuetify.breakpoint.lgOnly
                              "
                              clearable
                              class="mk-date-input-text-field"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="
                              sharePanelFormData['form' + batch.batch_id]
                                .due_date
                            "
                            no-title
                            scrollable
                            @input="hideInputField(batch.batch_id, 'due')"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>

                    <v-col cols="12" class="pa-0 ma-0">
                      <div class="mk-share-panel-time-alert">
                        <p>
                          {{
                            !$language
                              ? "Start Time Must Be Before Due Time."
                              : "শুরুর সময় নির্ধারিত সময়ের আগে হতে হবে।"
                          }}
                        </p>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="d-flex pr-0 py-0 ma-0 mk-due-date-section"
                      :style="
                        isSame(
                          sharePanelFormData['form' + batch.batch_id]
                            .share_date_for_view,
                          sharePanelFormData['form' + batch.batch_id]
                            .due_date_for_view
                        )
                          ? 'display:flex;'
                          : 'display:none;'
                      "
                    >
                      <div>
                        <v-menu :close-on-content-click="false" offset-y>
                          <template #activator="{ on }">
                            <v-text-field
                              :id="'time-input-from-' + idx"
                              v-model="
                                sharePanelFormData['form' + batch.batch_id]
                                  .available_time_from_for_view
                              "
                              outlined
                              :label="
                                !$language ? 'Available Time From' : 'সময় থেকে'
                              "
                              class="mk-time-input-text-field"
                              :placeholder="
                                !$language
                                  ? 'Select Time'
                                  : 'সময় নির্বাচন করুন'
                              "
                              append-icon="mdi-calendar-month-outline"
                              :dense="
                                $vuetify.breakpoint.mdAndDown ||
                                  $vuetify.breakpoint.lgOnly
                              "
                              hint="HH:MM format"
                              :rules="
                                !isEmpty(
                                  sharePanelFormData['form' + batch.batch_id]
                                    .available_time_from_for_view,
                                  sharePanelFormData['form' + batch.batch_id]
                                    .available_time_to_for_view
                                )
                                  ? rules
                                  : []
                              "
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-card class="elevation-0">
                            <time-picker
                              @input="
                                setTimeData($event, 'from', idx, batch.batch_id)
                              "
                            ></time-picker>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="d-flex pr-0 py-0 ma-0 mk-due-date-section"
                      :style="
                        isSame(
                          sharePanelFormData['form' + batch.batch_id]
                            .share_date_for_view,
                          sharePanelFormData['form' + batch.batch_id]
                            .due_date_for_view
                        )
                          ? 'display:flex;'
                          : 'display:none;'
                      "
                    >
                      <div>
                        <v-menu :close-on-content-click="false" offset-y>
                          <template #activator="{ on }">
                            <v-text-field
                              :id="'time-input-to-' + idx"
                              v-model="
                                sharePanelFormData['form' + batch.batch_id]
                                  .available_time_to_for_view
                              "
                              outlined
                              :label="
                                !$language ? 'Available Time To' : 'সময় পর্যন্ত'
                              "
                              :placeholder="
                                !$language ? 'Select Date' : 'সময় নির্বাচন করুন'
                              "
                              append-icon="mdi-calendar-month-outline"
                              hint="HH:MM format"
                              :dense="
                                $vuetify.breakpoint.mdAndDown ||
                                  $vuetify.breakpoint.lgOnly
                              "
                              :rules="
                                !isEmpty(
                                  sharePanelFormData['form' + batch.batch_id]
                                    .available_time_from_for_view,
                                  sharePanelFormData['form' + batch.batch_id]
                                    .available_time_to_for_view
                                )
                                  ? rules
                                  : []
                              "
                              class="mk-time-input-text-field"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-card class="elevation-0">
                            <time-picker
                              @input="
                                setTimeData($event, 'to', idx, batch.batch_id)
                              "
                            ></time-picker>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="px-0 pb-0 ma-0 mk-share-panel-form-input-mark-share-btn"
                  >
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pr-0 py-0 ma-0 mk-share-btn-section"
                    >
                      <div>
                        <label class="mk-share-switch">
                          <input
                            v-model="
                              sharePanelFormData['form' + batch.batch_id]
                                .is_share
                            "
                            type="checkbox"
                            :disabled="!isAllFieldVerified(batch.batch_id)"
                          />
                          <div class="mk-share-slider"></div>
                          <div class="text"></div>
                        </label>
                      </div>
                    </v-col>

                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pl-0 py-0 ma-0 mk-total-mark-section"
                    >
                      <!-- <div>
                      <v-text-field
                        v-model="sharePanelFormData['form' + batch.batch_id].total_score"
                        class="mk-date-input-text-field"
                        outlined
                        label="Mark"
                        placeholder="ex:100"
                        :dense="
                          $vuetify.breakpoint.mdAndDown
                            ? true
                            : $vuetify.breakpoint.lgOnly
                            ? true
                            : false
                        "
                        :rules="rules"
                        @input="shouldEnableShare(batch.batch_id)"
                      ></v-text-field>
                    </div> -->
                    </v-col>
                  </v-row>
                  <v-row
                    class="pb-0 ma-0 mk-share-panel-form-input-mark-share-btn"
                    style="padding-left: 65px"
                  >
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="px-0 py-0 ma-0 mk-share-btn-section"
                    >
                      <div>
                        <label class="mk-share-switch">
                          <input
                            v-model="
                              sharePanelFormData['form' + batch.batch_id]
                                .is_public
                            "
                            type="checkbox"
                          />
                          <div class="mk-share-slider"></div>
                          <div class="public-text"></div>
                        </label>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pl-0 py-0 ma-0 mk-total-mark-section d-flex justify-start"
                    >
                      <div class="d-flex align-center" style="height: 100%">
                        Is it available publicly?
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    class="pb-0 ma-0 mk-share-panel-form-input-mark-share-btn"
                    style="padding-left: 65px"
                  >
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="px-0 py-0 ma-0 mk-share-btn-section"
                    >
                      <div>
                        <label class="mk-share-switch">
                          <input
                            v-model="
                              sharePanelFormData['form' + batch.batch_id]
                                .is_static
                            "
                            type="checkbox"
                          />
                          <div class="mk-share-slider"></div>
                          <div class="static-text"></div>
                        </label>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pl-0 py-0 ma-0 mk-total-mark-section d-flex justify-start"
                    >
                      <div class="d-flex align-center" style="height: 100%">
                        Is it shared for a limited time?
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-row>
            </v-col>
          </v-row>
          <div class="mk-share-cancle-done-btn-section ">
            <v-row no-gutters justify="end" class="px-10 pb-8">
              <div class="pl-0 py-0 ma-0 mk-share-panel-cancel">
                <v-btn
                  depressed
                  tile
                  plain
                  class="custom-font-family"
                  @click="$emit('closePanel')"
                >
                  {{ !$language ? "Cancel" : "বাতিল করুন" }}
                </v-btn>
              </div>

              <div class="pa-0 mx-0 mx-0 mk-share-panel-done">
                <v-btn
                  depressed
                  tile
                  plain
                  :loading="should_show_loading_icon"
                  class="custom-font-family"
                  @click="organizeDataAndSend()"
                >
                  {{ !$language ? "Done" : "সম্পন্ন করুন" }}
                </v-btn>
              </div>
            </v-row>
          </div>
        </v-card>
        <div v-else>
          <v-card class="loading-mk-share-panel-header-card">
            <div id="loading-wrapper">
              <div id="loading-text">LOADING</div>
              <div id="loading-content"></div>
            </div>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </v-row>
</template>

<script>
import TimePicker from "#ecf/batch/components/TimePicker";
import batchService from "@ecf/modules/batch/services/BatchService";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "ExamSharePanel",
  components: { TimePicker },
  mixins: [FieldValidations],
  props: {
    examToShare: {
      type: Object,
      required: true
    },
    courseId: String,
    sharePanel: Boolean,
    should_show_loading_icon: Boolean
  },
  data: () => ({
    batches: [],
    rules: [v => this.required(v)], // does not work without this syntax
    valid: true,
    isPublic: false,
    isStatic: false,
    current_form_element: undefined,
    dialogMap: {},
    sharePanelFormData: {},
    timeData: {},
    finalFormData: {},
    make_form_visible: false
  }),
  computed: {
    shares() {
      return this.$store.state.exam.examShares[this.examToShare.id];
    }
  },
  async created() {
    this.make_form_visible = false;
    await this.fetchBatches();
    await this.fetchShares();
    this.setInitialData();
    this.make_form_visible = true;
  },
  methods: {
    setTimeData(payload, type, index, id) {
      let mod = payload.hour == 12 ? 12 : 0;
      let calculatedHour = String(
        (payload.hour +
          (payload.amPm == "PM" ? (12 + mod) % 24 : (0 + mod) % 24)) %
          24
      ).padStart(2, "0");
      let calculatedMinute = String(payload.minute).padStart(2, "0");
      let calculatedTime = `${calculatedHour}:${calculatedMinute}`;
      this.sharePanelFormData["form" + id][
        `available_time_${type}_for_view`
      ] = calculatedTime;

      const element = document.getElementById(`time-input-${type}-${index}`);
      if (element.getAttribute("type") != "time")
        element.setAttribute("type", "time");

      this.shouldEnableShare(id);
    },
    showInputField(id) {
      this.$set(this.dialogMap, id, true);
    },
    isDateFieldEmpty(id) {
      if (
        this.sharePanelFormData["form" + id].due_date &&
        this.sharePanelFormData["form" + id].share_date
      )
        return false;
      return true;
    },
    doesTimeFieldsHasData(id) {
      if (
        this.sharePanelFormData["form" + id].available_time_from_for_view !=
          "" &&
        this.sharePanelFormData["form" + id].available_time_to_for_view != ""
      )
        return true;
      return false;
    },
    hideInputField(id, token) {
      this.$set(this.dialogMap, token + id, false);
      if (token == "share") {
        this.$set(
          this.sharePanelFormData["form" + id],
          "share_date_for_view",
          this.formatDate(this.sharePanelFormData["form" + id].share_date)
        );
      } else if (token == "due") {
        this.$set(
          this.sharePanelFormData["form" + id],
          "due_date_for_view",
          this.formatDate(this.sharePanelFormData["form" + id].due_date)
        );
      }

      if (!this.isDateFieldEmpty(id)) {
        if (
          !this.isBefore(
            this.sharePanelFormData["form" + id].share_date_for_view,
            this.sharePanelFormData["form" + id].due_date_for_view
          )
        ) {
          let form = document.getElementById(id);
          let alert = form.getElementsByClassName("mk-share-panel-alert")[0];
          alert.style = "visibility:visible;";
        } else {
          let form = document.getElementById(id);
          let alert = form.getElementsByClassName("mk-share-panel-alert")[0];
          alert.style = "visibility:hidden;";
        }

        if (
          !this.isValidDeadLine(
            this.sharePanelFormData["form" + id].due_date_for_view
          )
        ) {
          let form = document.getElementById(id);
          let alert = form.getElementsByClassName(
            "mk-share-panel-time-alert-deadline"
          )[0];
          alert.style = "visibility:visible;";
        } else {
          let form = document.getElementById(id);
          let alert = form.getElementsByClassName(
            "mk-share-panel-time-alert-deadline"
          )[0];
          alert.style = "visibility:hidden;";
        }
      }

      this.shouldEnableShare(id);
    },

    isTimeBefore(start, end) {
      let [h1, m1] = start.split(":");
      let [h2, m2] = end.split(":");
      if (h1 > 12) {
        if (h2 > 12) {
          if (h1 === h2) {
            return m1 < m2;
          } else if (h1 < h2) {
            return true;
          }
          return false;
        }
        return false;
      } else {
        if (h2 <= 12) {
          if (h1 === h2) {
            return m1 < m2;
          } else if (h1 > h2) {
            return false;
          }
          return true;
        }
        return true;
      }
    },

    isAllFieldVerified(id) {
      if (this.sharePanelFormData["form" + id].is_static) {
        if (
          this.sharePanelFormData["form" + id].share_date_for_view &&
          this.doesTimeFieldsHasData(id) &&
          this.isTimeBefore(
            this.sharePanelFormData["form" + id].available_time_from_for_view,
            this.sharePanelFormData["form" + id].available_time_to_for_view
          )
        ) {
          this.sharePanelFormData["form" + id].is_share = true;
          return true;
        }
        this.sharePanelFormData["form" + id].is_share = false;
        return false;
      } else {
        if (this.isDateFieldEmpty(id)) {
          this.sharePanelFormData["form" + id].is_share = false;
          return false;
        } else {
          if (
            this.sharePanelFormData["form" + id].share_date_for_view &&
            this.isBefore(
              this.sharePanelFormData["form" + id].share_date_for_view,
              this.sharePanelFormData["form" + id].due_date_for_view
            ) &&
            this.isValidDeadLine(
              this.sharePanelFormData["form" + id].due_date_for_view
            ) &&
            this.doesTimeFieldsHasData(id) &&
            this.isTimeBefore(
              this.sharePanelFormData["form" + id].available_time_from_for_view,
              this.sharePanelFormData["form" + id].available_time_to_for_view
            )
          ) {
            this.sharePanelFormData["form" + id].is_share = true;
            return true;
          }
          this.sharePanelFormData["form" + id].is_share = false;
          return false;
        }
      }
    },

    shouldEnableShare(id) {
      this.sharePanelFormData["form" + id].is_share = this.isAllFieldVerified(
        id
      );
      this.shouldActiveTimeAlert(id);
    },

    isBefore(start, end) {
      if (end === undefined || end === "") return true;
      let [d1, m1, y1] = start.split("/");
      let [d2, m2, y2] = end.split("/");
      if (y1 === y2) {
        if (m1 === m2) {
          if (d1 === d2) return true;
          return d1 < d2;
        }
        return m1 < m2;
      }
      return y1 < y2;
    },

    isValidDeadLine(end) {
      if (end === undefined || end === "") return true;
      const today = new Date();
      let [d1, m1, y1] = [
        today.getDate(),
        today.getMonth() + 1,
        today.getFullYear()
      ];
      let [d2, m2, y2] = end.split("/").map(item => {
        return parseInt(item);
      });
      if (y1 === y2) {
        if (m1 === m2) {
          if (d1 === d2) return true;
          return d1 < d2;
        }
        return m1 < m2;
      }
      return y1 < y2;
    },

    isSame(start, end, id = "") {
      if (start != null && end != null) {
        let [d1, m1, y1] = start.split("/");
        let [d2, m2, y2] = end.split("/");
        return y1 == y2 && m1 == m2 && d1 == d2 && start != "" && end != "";
      }
      return false;
    },

    isEmpty(available_time_from, available_time_to) {
      return available_time_from === "" && available_time_to === "";
    },

    setInitialData() {
      this.batches.forEach((element, index) => {
        this.$set(this.dialogMap, "share" + element.batch_id, false);
        this.$set(this.dialogMap, "due" + element.batch_id, false);
        if (this.shares[element.batch_id]) {
          this.$set(this.sharePanelFormData, "form" + element.batch_id, {
            share_date: this.shares[element.batch_id].start_date,
            due_date: this.shares[element.batch_id].end_date,
            share_date_for_view: this.formatDate(
              this.shares[element.batch_id].start_date
            ),
            due_date_for_view: this.formatDate(
              this.shares[element.batch_id].end_date
            ),
            available_time_from_for_view: this.shares[element.batch_id]
              .start_time,
            available_time_to_for_view: this.shares[element.batch_id].end_time,
            // total_score: this.shares[element.batch_id].total_score,
            is_share: true,
            should_highlight: true,
            batch_id: element.batch_id,
            course_id: element.course_id,
            exam_id: this.shares[element.batch_id].exam_id,
            title: element.title,
            is_clicked: false,
            should_show_time_field: true,
            is_public: this.shares[element.batch_id].publicly_available,
            is_static: this.shares[element.batch_id].static_content
          });
        } else {
          this.$set(this.sharePanelFormData, "form" + element.batch_id, {
            share_date: "",
            due_date: "",
            share_date_for_view: "",
            due_date_for_view: "",
            available_time_from_for_view: "",
            available_time_to_for_view: "",
            // total_score: "",
            is_share: false,
            should_highlight: false,
            batch_id: element.batch_id,
            course_id: "",
            exam_id: "",
            title: "",
            is_clicked: false,
            should_show_time_field: false,
            is_public: false,
            is_static: false
          });
        }
      });
      this.make_form_visible = true;
      if (this.batches.length != 0) {
        setTimeout(this.hilightFirstBatch, 10);
      }
    },
    hilightFirstBatch() {
      this.showForm(this.batches[0].batch_id);
    },

    shouldActiveTimeAlert(id) {
      if (
        this.doesTimeFieldsHasData(id) &&
        !this.isTimeBefore(
          this.sharePanelFormData["form" + id].available_time_from_for_view,
          this.sharePanelFormData["form" + id].available_time_to_for_view
        ) &&
        this.isSame(
          this.sharePanelFormData["form" + id].share_date_for_view,
          this.sharePanelFormData["form" + id].due_date_for_view
        )
      ) {
        let form = document.getElementById(id);
        let alert = form.getElementsByClassName("mk-share-panel-time-alert")[0];
        alert.style = "visibility:visible;";
      } else {
        let form = document.getElementById(id);
        let alert = form.getElementsByClassName("mk-share-panel-time-alert")[0];
        alert.style = "visibility:hidden;";
      }
    },

    async fetchBatches() {
      try {
        this.batches = await batchService.find(this.courseId);
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e)
        ]);
      }
    },
    async fetchShares() {
      try {
        await this.$store.dispatch("exam/listSharedBatches", {
          examId: this.examToShare.id,
          courseId: this.courseId
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    organizeDataAndSend() {
      this.finalFormData = {};
      for (var key in this.sharePanelFormData) {
        if (this.sharePanelFormData[key].is_share) {
          if (this.sharePanelFormData[key].is_static) {
            this.$set(this.finalFormData, key, {
              start_date: this.formatDateToSend(
                this.sharePanelFormData[key].share_date_for_view
              ),
              start_time: this.sharePanelFormData[key]
                .available_time_from_for_view,
              end_time: this.sharePanelFormData[key].available_time_to_for_view,
              batch_id: this.sharePanelFormData[key].batch_id,
              publicly_available: this.sharePanelFormData[key].is_public,
              static_content: this.sharePanelFormData[key].is_static
            });
          } else {
            this.$set(this.finalFormData, key, {
              start_date: this.formatDateToSend(
                this.sharePanelFormData[key].share_date_for_view
              ),
              end_date: this.formatDateToSend(
                this.sharePanelFormData[key].due_date_for_view
              ),
              start_time: this.sharePanelFormData[key]
                .available_time_from_for_view,
              end_time: this.sharePanelFormData[key].available_time_to_for_view,
              // total_score: this.sharePanelFormData[key].total_score,
              batch_id: this.sharePanelFormData[key].batch_id,
              publicly_available: this.sharePanelFormData[key].is_public,
              static_content: this.sharePanelFormData[key].is_static
            });
          }
        }
      }
      this.$emit("shareExam", this.finalFormData);
    },

    formatDateToSend(inputDate) {
      if (!inputDate) return null;
      const [day, month, year] = inputDate.split("/");
      return `${year}-${month}-${day}`;
    },

    showForm(id) {
      let target_form_element = document.getElementById(id);
      if (this.current_form_element != undefined) {
        document.getElementById(this.current_form_element).style =
          "display:none";
        if (
          this.sharePanelFormData["form" + this.current_form_element].is_share
        ) {
          this.sharePanelFormData[
            "form" + this.current_form_element
          ].should_highlight = true;
          this.$set(
            this.sharePanelFormData["form" + this.current_form_element],
            "is_clicked",
            false
          );
        } else {
          this.sharePanelFormData[
            "form" + this.current_form_element
          ].should_highlight = false;
          this.$set(
            this.sharePanelFormData["form" + this.current_form_element],
            "is_clicked",
            false
          );
        }
      }

      target_form_element.style = "display:block";
      this.dialog = true;
      this.$set(this.sharePanelFormData["form" + id], "should_highlight", true);
      this.$set(this.sharePanelFormData["form" + id], "is_clicked", true);
      this.current_form_element = id;
    },

    setSmallScreenStyle() {
      let text_field = document.getElementsByClassName(
        "mk-date-input-text-field"
      );
      Array.from(text_field).forEach(element => {
        element.getElementsByTagName("fieldset")[0].style =
          "border-radius:0px !important; height:45px;";
        element.getElementsByClassName("v-text-field__slot")[0].style =
          "height:45px;";
        element.style = "font-size:12px !important;";
        let claendar = element.getElementsByTagName("i");
        if (claendar.length > 0) {
          claendar[0].style = "font-size:22px;";
        }
      });

      let time_text_field = document.getElementsByClassName(
        "mk-time-input-text-field"
      );
      Array.from(time_text_field).forEach(element => {
        element.getElementsByTagName("fieldset")[0].style =
          "border-radius:0px !important; height:45px;";
        element.getElementsByClassName("v-text-field__slot")[0].style =
          "height:45px;";
        element.style = "font-size:12px !important;";
        let claendar = element.getElementsByTagName("i");
        if (claendar.length > 0) {
          claendar[0].style = "font-size:22px;";
        }
      });
      return "520px";
    },

    setMediumScreenStyle() {
      let text_field = document.getElementsByClassName(
        "mk-date-input-text-field"
      );
      Array.from(text_field).forEach(element => {
        element.getElementsByTagName("fieldset")[0].style =
          "border-radius:0px !important; height:50px;";
        element.getElementsByClassName("v-text-field__slot")[0].style =
          "height:50px;";
        element.style = "font-size:14px !important;";
        let claendar = element.getElementsByTagName("i");
        if (claendar.length > 0) {
          claendar[0].style = "font-size:25px;";
        }
      });
      let time_text_field = document.getElementsByClassName(
        "mk-time-input-text-field"
      );
      Array.from(time_text_field).forEach(element => {
        element.getElementsByTagName("fieldset")[0].style =
          "border-radius:0px !important; height:50px;";
        element.getElementsByClassName("v-text-field__slot")[0].style =
          "height:50px;";
        element.style = "font-size:14px !important;";
        let claendar = element.getElementsByTagName("i");
        if (claendar.length > 0) {
          claendar[0].style = "font-size:25px;";
        }
      });
      return "870px";
    },

    setLargeScreenStyle() {
      let text_field = document.getElementsByClassName(
        "mk-date-input-text-field"
      );
      Array.from(text_field).forEach(element => {
        element.getElementsByTagName("fieldset")[0].style =
          "border-radius:0px !important; height:60px;";
        element.getElementsByClassName("v-text-field__slot")[0].style =
          "height:60px;";
        element.style = "font-size:18px !important;";
        let claendar = element.getElementsByTagName("i");
        if (claendar.length > 0) {
          claendar[0].style = "font-size:32px;";
        }
      });
      let time_text_field = document.getElementsByClassName(
        "mk-time-input-text-field"
      );
      Array.from(time_text_field).forEach(element => {
        element.getElementsByTagName("fieldset")[0].style =
          "border-radius:0px !important; height:60px;";
        element.getElementsByClassName("v-text-field__slot")[0].style =
          "height:60px;";
        element.style = "font-size:18px !important;";
        let claendar = element.getElementsByTagName("i");
        if (claendar.length > 0) {
          claendar[0].style = "font-size:32px;";
        }
      });
      return "1198px";
    }
  }
};
</script>

<style scoped lang="scss">
@import "#ecf/exam/styles/ExamSharePanel.scss";
</style>
