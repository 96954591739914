import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
import { checkStatus } from "/global/utils/helpers";
export default {
  URL: "batches/",

  /**
   * @return Promise<AxiosResponse>
   * @param courseId
   * @param opts
   */
  find(courseId, opts = {}) {
    // todo maybe do some transformation. Not needed for now

    return $backend
      .get("teachers_get_batches", { params: { course_id: courseId }, ...opts })
      .then(checkStatus)
      .then(r => {
        return r;
      })
      .then(r => r.data.content.batches);
  },
  /**
   * @return Promise
   * @param batch
   * @param headers
   */
  save(courseId, batch, headers = {}) {
    return $backend
      .post("teachers_post_batches", { course_id: courseId, ...batch }, headers)
      .then(checkStatus)
      .then(r => r.data.content);
  },
  delete(model, opts = {}) {
    const data = {
      batch_id: model.batch_id
    };

    let config = { data, ...opts };
    return $backend.delete("teachers_delete_batches", config).then(checkStatus);
  }
};
